import React from "react"
import TextyAnim from "rc-texty"

export const Animetext = props => (
  <TextyAnim
  className={props.className}
    type="mask-top"
    duration={e => {
      if (e.index === 2) {
        return 500
      }
      return props.duration
    }}
    interval={e => {
      if (e.index === 2) {
        return 500
      }
      return e.index * props.interval
    }}
    onEnd={type => {
      // console.log(type)
    }}
  >
    {props.text}
  </TextyAnim>
)

