import React from 'react'
import Lottie from "lottie-react";
import Animation from "../utils/lottie.json";

export const Loader = () => {
    return (
        <div className="app-page-loader">
            <div className="anime-box">
                <Lottie animationData={Animation} />
                <h2>Loading...</h2>
            </div>
        </div>
    )
}
