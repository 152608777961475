import React from "react"
import * as Component from "./"
import ReactPlayer from "react-player"
import { Container, Button } from "react-bootstrap"
import mainVideo from "../assets/videos/bg.mp4"

export const Mainframe = () => (
  <section className="mainframe">
  <div className="player-wrapper d-none d-md-block">
    <ReactPlayer
      className="react-player"
      url={mainVideo}
      playing
      muted
      loop
      width="100%"
      height="100%"
      controls={false}
      youtubeconfig={{ playerVars: { showinfo: 1 } }}
    />
  </div>
  <Container>
    <div className="content-box">
    <Component.Animetext text="Making" className="h3-em" />
    <Component.Animetext text="the world through" duration={800} interval={50} className="h3"  />
    <Component.Animetext text="face recognition" duration={800} interval={50} className="h2" />
    <Component.Animetext text="The company" duration={1000} interval={50} className="p-em" />
    <Component.Animetext
    className="p"
          duration={80}
          interval={5}
          text="Emirates Face Recognition LLC (Emirates FR) creates state-of-the-art
        face recognition technology and turn-key solutions for facilitating
        the secure clearance of persons through border controls and the
        movement of passengers through airports, protecting critical
        infrastructure and key assets vital to the nation, assisting law
        enforcement identify people and establishing trusted 'secure value
        exchange' for machine-assisted service delivery"
        />
      <Button varient="primary" className="btn-animate">
        <span>Read more</span>
      </Button>
    </div>
  </Container>
</section>
)