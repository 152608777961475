import React from "react"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Row, Col, Carousel } from "react-bootstrap"

export const Sectionone = () => (
  <section className="index-one">
    <Container>
      <Row>
        <Col sm={6}>
          <ScrollAnimation
            className="section-two"
            animateIn="fadeInLeft"
            initiallyVisible={true}
          >
            <article className="box-one">
              <h3>TECHNOLOGY</h3>
              <p>
                Emirates FR face recognition technology and solutions have been
                designed for use in the most challenging and demanding
                application areas; those being, clearing people through
                Immigration controls, facilitating passenger movement through
                international airports and forensic identification of persons by
                law enforcement. Emirates FR technology and solutions have been
                optimised and extensively tested in demanding real-world
                settings to ensure realisation of the highest levels of accuracy
                and performance in a matter of seconds
              </p>
            </article>
          </ScrollAnimation>
        </Col>
        <Col sm={6}>
          <ScrollAnimation
            className="section-two"
            animateIn="fadeInRight"
            initiallyVisible={true}
          >
            <article className="box-two">
              <h3>NEWSFEED</h3>
              <Carousel>
                <Carousel.Item>
                  <p>
                    <span>
                      <strong>30/07/2015</strong>
                        Emirates Face Recognition LLC to Deploy Face Recognition Infrastructure at all UAE Airports
                    </span>
                      Emirates Face Recognition LLC (www.facerecognition.ae) chosen to supply UAE eBorder Face Recognition Infrastructure
                  </p>
                  <Link className="item-link" to="\">
                    Read More
                  </Link>
                </Carousel.Item>
                <Carousel.Item>
                  <p>
                    <span>
                      <strong>30/11/2014</strong>
                      UAE Ministry of Interior and AGNOSCO Establish 'Emirates Face Recognition LLC'
                    </span>
                      UAE Ministry of Interior (www.moi.gov.ae/en/) establishes joint venture, 'Emirates Face Recognition LLC' with AGNOSCO Vision Systems Inc. to address face recognition needs of Government and private sector in the UAE and the Middle East
                  </p>
                  <Link className="item-link" to="\">
                    Read More
                  </Link>
                </Carousel.Item>
              </Carousel>
            </article>
          </ScrollAnimation>
        </Col>
        <Col lg={6}>
          <article className="box-three">
            <h3>Solutions</h3>
            <Row>
              <Col xs={6} sm={4} lg={6}>
                <ScrollAnimation
                  className="section-two"
                  animateIn="zoomIn"
                  initiallyVisible={true}
                >
                  <article className="list-one">
                    <span>01</span> <p>Secure Facilitated Border Clearance</p>
                  </article>
                </ScrollAnimation>
              </Col>
              <Col xs={6} sm={4} lg={6}>
                <ScrollAnimation
                  className="section-two"
                  animateIn="zoomIn"
                  initiallyVisible={true}
                >
                  <article className="list-two">
                    <span>02</span> <p>Forensic Law Enforcement</p>
                  </article>
                </ScrollAnimation>
              </Col>
              <Col xs={6} sm={4} lg={6}>
                <ScrollAnimation
                  className="section-two"
                  animateIn="zoomIn"
                  initiallyVisible={true}
                >
                  <article className="list-three">
                    <span>03</span> <p>Pre-Emptive Surveillance</p>
                  </article>
                </ScrollAnimation>
              </Col>
              <Col xs={6} sm={4} lg={6}>
                <ScrollAnimation
                  className="section-two"
                  animateIn="zoomIn"
                  initiallyVisible={true}
                >
                  <article className="list-four">
                    <span>04</span> <p>Airport Services</p>
                  </article>
                </ScrollAnimation>
              </Col>
              <Col xs={6} sm={4} lg={6}>
                <ScrollAnimation
                  className="section-two"
                  animateIn="zoomIn"
                  initiallyVisible={true}
                >
                  <article className="list-five">
                    <span>05</span> <p>Secure Value Exchange</p>
                  </article>
                </ScrollAnimation>
              </Col>
            </Row>
          </article>
        </Col>
      </Row>
    </Container>
  </section>
)
