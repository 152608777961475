import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav, Container } from "react-bootstrap"

export const Footer = () => (
  <footer>
    <Navbar expand="lg">
      <Container>
        <Nav className="mr-auto">
          <Link className="item-link" to="/">
            HOME
          </Link>
          <Link className="item-link" to="/solutions">
            COMPANY
          </Link>
          <Link className="item-link" to="/solutions">
            SOLUTIONS
          </Link>
          <Link className="item-link" to="/contact">
            CONTACT
          </Link>
          <Link className="item-link" to="/contact">
            SITE MAP
          </Link>
          <Link className="item-link" to="/">
            TERMS & CONDITIONS
          </Link>
        </Nav>
        <Nav className="ml-auto">
          <Link className="item-link" to="/">
            COPYRIGHT © 2020 Emirates Face Recognition LLC.
          </Link>
        </Nav>
      </Container>
    </Navbar>
  </footer>
)

