import React from "react"

export const Icon = props => {
  return (
    <i
      className={`icon icon-${props.icon}`}
      style={{ fontSize: props.size, color: props.color }}
    />
  )
}
