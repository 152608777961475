import React, {useState} from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap"
import Logo from "../assets/images/logo.png"

export const Header = (props) => {
  const [toggle, setToggle] = useState(false)
  const isBrowser = typeof window !== "undefined"
  const data = isBrowser && window.location.pathname;

  const handleCareer = e => {
    if (isBrowser) {
      window.localStorage.setItem('isCareer', e)
      props.isClick(e)
    }
  }

  const handleSolution = e => {
    if (isBrowser) {
      window.localStorage.setItem('isSolutions', e)
      props.isClick(e)
    }
  }

  return (
    <React.Fragment>
    {/* PAGE META  */}
    <Helmet><title>Emirates - Face Recognition</title></Helmet>

    {/* HEADER  */}
    <header>
      <Navbar expand="lg" className='d-none d-lg-flex'>
        <Navbar.Brand>
          <Link to="/"> 
            <img src={Logo} className="img-fluid" alt="logo"/>
          </Link>
        </Navbar.Brand>
        <Nav className="ml-auto">
          <ul>
            <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/">
                HOME
              </Link>
            </li>
            <li className="nav-item">
              <NavDropdown
                title="Company"
                id="basic-nav-dropdown"
                className={data === "/career" && 'active'}
              >
                <Link
                  className="dropdown-item"
                  onClick={() => handleCareer('overview')}
                  to="/career"
                >
                  OVERVIEW
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={() => handleCareer('career')}
                  to="/career"
                >
                  CAREER
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={() => handleCareer('legal')}
                  to="/career"
                >
                  LEGAL NOTICE
                </Link>
              </NavDropdown>
            </li>
            <li className="nav-item">
              <NavDropdown
                title="Solutions"
                id="basic-nav-dropdown"
                className={data === "/solutions" && 'active'}
              >
                <Link
                  className="dropdown-item"
                  to="/solutions"
                  onClick={() => handleSolution('first')}
                >
                  Secure Facilitated Border Clearance
                </Link>
                <Link
                  className="dropdown-item"
                  to="/solutions"
                  onClick={() => handleSolution('second')}
                >
                  Forensic Law Enforcement
                </Link>
                <Link
                  className="dropdown-item"
                  to="/solutions"
                  onClick={() => handleSolution('third')}
                >
                  Pre-Emptive Surveillance
                </Link>
                <Link
                  className="dropdown-item"
                  to="/solutions"
                  onClick={() => handleSolution('fourth')}
                >
                  Airport Services
                </Link>
                <Link
                  className="dropdown-item"
                  to="/solutions"
                  onClick={() => handleSolution('fifth')}
                >
                  Secure Value Exchange
                </Link>
              </NavDropdown>
            </li>
            <li className="nav-item">
              <Link className="nav-link" activeClassName="active" to="/contact">
                CONTACT
              </Link>
            </li>
          </ul>
          <Button variant="primary">عربي</Button>
        </Nav>
      </Navbar>

      {/* MEGA MENU */}
      <div className={`d-block d-lg-none mega-menu ${toggle ? 'is-active' : ''}`}>
        <div className="header-top">
          <Navbar.Brand>
            <Link to="/">
              <img src={Logo} className="img-fluid" alt="logo" />
            </Link>
          </Navbar.Brand>
          <button className={`hamburger hamburger--collapse ${toggle ? 'is-active': ''}`} type="button" onClick={() => setToggle(!toggle)}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div className="header-content">
        <Nav>
          <Nav.Item>
            <Link onClick={() => setToggle(!toggle)} to="/">Home</Link>
          </Nav.Item>
          <Nav.Item>
            <Link onClick={() => setToggle(!toggle)} to="/career">Company</Link>
          </Nav.Item>
          <Nav.Item>
            <Link onClick={() => setToggle(!toggle)} to="/solutions">Solutions</Link>
          </Nav.Item>
          <Nav.Item>
            <Link onClick={() => setToggle(!toggle)} to="/contact">Contact</Link>
          </Nav.Item>
        </Nav>

        </div>
      </div>
    </header>
    </React.Fragment>
    )
  }

