import React, { useState, useEffect } from "react"
import * as Component from './'

export const Layout = props => {
  const [isLoader, setLoader] = useState(false)
  const [isLocation, setLocation] = useState(null)

  useEffect(() => {
    let isLoad = true
    if (isLoad) {
      setTimeout(() => {
        setLoader(true)
        setLocation(window.location.pathname)
      }, 1000);
    }
    return () => {
      isLoad = false
    }
  }, [])

  return (
    <React.Fragment>
      <section
        className={`layout ${props.className !== undefined ? props.className : ""
          }`}
      >
        <Component.Header isClick={e => isLocation === "/career" || isLocation === "/solutions" ? props.isClick(e) : ''} />
        <main
          className={`main ${props.container !== undefined ? props.container : ""
            }`}
        >
          {props.children}
        </main>
        <Component.Footer />
      </section>
      {!isLoader && <Component.Loader />}
    </React.Fragment>
  )
}
